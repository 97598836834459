<script>
import Layout from "@/views/pages/employee/layout/main";
import scheduleComponent from "./schedules-component.vue";
export default {
  
  components: {
    Layout,
    scheduleComponent,
  },

  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <scheduleComponent/>
  </Layout>
</template>