<script>
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import calendar from "@/views/pages/employee/schedule/components/calendar";
import weeklyShift from "@/views/pages/employee/schedule/components/weeklyShift";
// import sidebar from "@/views/pages/admin/calendar/components/sidebar.vue";
import moment from "moment";
export default {
  page: {
    title: "Shifts Calendar",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    calendar,
    weeklyShift,
    // sidebar,
  },

  // props:{
  //   showshift:{
  //     type:  Boolean,
  //     default: true,
  //   }
  // },
  data() {

    return {
      items: [
        {
          text: "Schedule",
        },
        {
          text: "Shifts",
          active: true,
        },
      ],

      //calendar
      loading : false,
      selectedShifts : [],
      weeklySelectedShift : [],
      totalshifts : {},
      shifts : [],
      table_data: {},
      isClick: false,
      showCalender : true,
      currentLocation : {},
      currentLocationText : new Date(),
    };
  },

  mounted() {
    document.body.classList.add("authentication-bg");
    this.fetchSelectedShift({callApi : false});
    this.weeklySelectedShifts();
  },

  methods : {
    weeklySelectedShifts()
    {
      this.loading = true;
      let para = {
        employee_id : this.currentUser.employee.id , 
        /*only send the current month selected shifts*/
        // from : moment().add('weeks').startOf('isoWeek').format('YYYY-MM-DD'),
        // to : moment().add(2 ,'week').endOf('isoWeek').format('YYYY-MM-DD'),
      }
      this.$axios
        .get("shift/employee/selected-shifts", {params : para})
        .then((response) => {
          let data = response.data.payload;
          this.weeklySelectedShift = data;
          this.loading = false;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchSelectedShift({callApi = true}={} , table_data_param = null) {
      /* table_data_param is when employee accept/ release a shift this api hits again, 
      so when it hits again it should have (to and from) param to fetch specific data*/
      this.loading = true;
      let apiParams = {
        employee_id : this.currentUser.employee.id , 
        /*only send the current month selected shifts*/
        // from : table_data_param?.from ?? moment().startOf('month').format('YYYY-MM-DD'),
        // to : table_data_param?.to ?? moment().add(1 ,'month').startOf('month').format('YYYY-MM-DD'),
      }
      this.$axios
        .get("shift/employee/selected-shifts", {params : apiParams})
        .then((response) => {
          let data = response.data.payload;
          console.log(data);
          this.selectedShifts = data;
          this.loading = false;
          this.fetchWeeklyShift(callApi , table_data_param);
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchWeeklyShift(callApi , table_data_param) {
      let dtParam = {
        ...this.table_data, group_by_week: true
      };

      this.$axios
        .get("shift/employee/shift-by-role/" + this.currentUser.employee.id, {
          params: dtParam,
        })
        .then((response) => {
          let data = response.data.payload;
          this.totalshifts = data;
          // if(this.showshift){
          this.$refs.WeeklyShifts.shiftscard(data);
          if (Object.entries(data["shifts"]).length > 2) {
            this.$refs.WeeklyShifts.moreExists = true;
          }
        // }
          this.showCalender = true;
        
          if(callApi)
          {  //call this api only when shift is picked by employee to color the shift banner. should not run for the sidebar filters
            this.fetchCalendarShift(table_data_param); 
          }
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.error(error);
        });
    },

    fetchCalendarShift(table_data) {
      this.$axios
        .get("shift/employee/shift-by-role/" + this.currentUser.employee.id, {
          params: table_data,
        })
        .then((response) => {
          let data = response.data.payload;
          this.shifts = data.shifts;
          this.$refs.calendar.calendarOptions.events = [];
          this.$refs.calendar.buildEvents(this.shifts);
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
          console.error(error);
        });
    },

    listenFromSideBar(newData) {
      if (newData?.shift_name) {
        this.table_data.name = newData?.shift_name;
        if (newData?.shift_name == "All") {
            this.table_data.name = null;
        }
      }
      if (newData?.location) {
        if(newData?.location == "showAll"){
          this.currentLocation = {};
          this.table_data.location_id = null;
          this.currentLocationText = new Date();
        }else{
        this.table_data.location_id = newData.location.id;
        this.currentLocation = newData.location.time_zone;
        this.currentLocationText = newData.location.time_zone.text;
        }

      }
      if (newData?.area) {
        this.table_data.area_id = newData?.area?.id;
      }
      // if (newData?.endDate) {
      //   this.table_data.from = newData.startDate;
      //   this.table_data.to = newData.endDate;
      // }
      if(newData?.role) {
        this.table_data.area_role_id = newData?.role?.id;
      }

      if (newData?.clearFilters) {
          this.currentLocationText = new Date();
          this.table_data = {
            pagination: 0,
          };
      }

      if(newData != null)
      {
        let params = {};
        let tdata =  this.$refs.calendar.table_data;
          params = {
            ...tdata, 
            ...this.table_data,
          }
        this.fetchSelectedShift({callApi: false});
        this.fetchCalendarShift(params);
      }
    },
  },

  middleware: "authentication",
};
</script>

<template>
  <div>
    <PageHeader :items="items" />
    <div
      v-if="
        currentUser.employee.status == 'Active' ||
        currentUser.employee.status == 'Updated' ||
        currentUser.employee.status == 'Rejoin'
      "
      class="row"
    >
      <!-- <div
        class="col-xl-2 position-relative ps-0 transition_class"
        :class="{ fullWidth: isClick }"
      >
        <sidebar ref="sideBar" @filterData="listenFromSideBar" />
        <i
          class="
            fas
            position-absolute
            top-0
            end-0
            px-4
            f-16
            primaryColor
            pointer
          "
          @click="isClick = !isClick"
          :class="isClick == false ? 'fa-times' : 'fa-bars'"
        >
        </i>
      </div> -->

      <div class="col-xl-12" :class="{ 'col-xl-12': isClick }">
        <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
        <h6 class="text-end text-pink">Timezone : {{currentLocationText}}</h6>
        <div class="card">
            <div class="card-body under-tabs">
              <div class="row">
                <div class="col-lg-12">
<!--                   
                    in weeklyshifts - @selectedShifts method is for when user accpt the shift from weekly shift module it should also update on the calendar to change color
                    in calendar - @weeklySelectedShifts method is when the shift is accpt from calendar it should also make disble on weekly shifts module
                   -->
                    <weeklyShift  :selectedShifts="weeklySelectedShift" ref="WeeklyShifts" :totalshifts="totalshifts" @weeklySelectedShifts="weeklySelectedShifts" @selectedShifts="fetchSelectedShift" :currentLocation="currentLocation"/>
                    <calendar v-if="showCalender" :selectedShifts="selectedShifts" ref="calendar" :shifts="shifts" @calendarShift="fetchCalendarShift" @selectedShifts="fetchSelectedShift" :currentLocation="currentLocation" @weeklySelectedShifts="weeklySelectedShifts" />
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
