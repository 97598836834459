<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import timeSlot from "@/mixins/timeSlot";
/**
 * Add-product component
 */
export default {
  mixins:[timeSlot],
  components: {
    FullCalendar,
  },

  props : {
    selectedShifts : {
      type: [Array,Object],
      default: () => {},
    },
    shifts : {
      type : [Array,Object],
      default: () => {},
    },
    currentLocation : {
      type: [Object],
      default : () => {},
    },
  },
  data() {
    return {
      title: "Schedule",
      processing: false,
      items: [
        {
          text: "Schedule",
        },
        {
          text: "Schedule",
          active: true,
        },
      ],
      renderCalendar: true,
      calendarOptions: {
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        events:[],
        editable: true,
        droppable: true,
        eventResizableFromStart: true,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,

        customButtons:{
          prev:{
            text: "PREV", 
            click: () => {           
              this.fetchPrevData();
            }
          },
          next: { // this overrides the next button
            text: "Next",
            click: () => {
              this.fetchNextData();
            }
          }
        },
      },
      currentEvents: [],
      eventModal: false,
      releaseShiftModel: false,
      submitted: false,
      submit: false,
      edit: {},
      editevent: {
        editTitle: null,
        shift: {},
      },
      class_name: null,
      status: null,
      select_release_reason: null,
      release_reason: null,
      release_status: null,
      release_document: null,
      shift_id: null,
      timeline_id: null,
      current_timeline_id: null,
      loading : false,

      calendarRef:null,
      shift_role_id: null,
      table_data:{
          from:null,
          to:null,
          name: null,
          pagination: 0 
      },
    };
  },
  watch: {
    "calendarRef.view.type": {
      handler: function (newValue, oldValue) {
        if (oldValue != undefined) {
          let getCalendarDates = this.$refs.fullCalendar?.getApi();
          this.table_data.from = this.formatDateYMD(getCalendarDates.view.currentStart);
          this.table_data.to = this.formatDateYMD(getCalendarDates.view.currentEnd);
          this.$emit('calendarShift', this.table_data);
        }
      },
      immediate: false
    },
  },
  methods: {
    changeTimeZone(date)
    {
      if(Object.entries(this.currentLocation).length > 0){
        let zone = this.currentLocation.utc[0];
        return this.changeDateTimeTimeZone(date,zone);
      }else{
        return this.DateWithMonthName(date, 'DD-MM-YYYY hh:mm A');
      }
    },

    shiftsHandler(shift) {
      
      this.selectedShifts.map((selectedShift) => {
        if (shift.id == selectedShift.shift_id) {
          if (selectedShift.status == "Pending") {
            this.status = selectedShift.status;
            this.class_name = "primaryColorBg text-white";
          } else if (selectedShift.status == "Accepted") {
            this.class_name = "secondary-bg text-white";
            this.status = selectedShift.status;
          } else if (
            selectedShift.status == "Released" ||
            selectedShift.status == "Apply For Leave"
          ) {
            this.class_name = "bg-warning  text-white";
            this.status = selectedShift.status;
          } else {
            this.class_name = "bg-danger  text-white";
            this.status = selectedShift.status;
          }
          this.timeline_id = selectedShift.id;
        }
      });
      return;
    },

    buildEvents(shifts) {
      this.calendarOptions.events = shifts.map((shift) => {
        if(new Date(shift.from) <  new Date())
        {
          this.class_name = "bg-dark text-white"
        }else{
          this.class_name = "bg-info text-white";
        }
        this.status = null;
        this.shiftsHandler(shift);
        let forPremium = "";
        if (shift.shift_type == "Premium") {
          forPremium = " (" + shift.shift_type + ")*";
        }
        return {
          id: shift.id,
          title: shift.name + " " + forPremium,
          start: this.changeFormatTimeCalendarBanner(this.changeTimeZone(shift.from)),
          end: this.changeFormatTimeCalendarBanner(this.changeTimeZone(shift.to)),
          className: this.class_name,
          status: this.status,
          backgroundColor: shift.label_color,
          shift: shift,
          timeline_id: this.timeline_id,
        };
      });
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.releaseShiftModel = false;
      this.event = {};
    },
    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    submitShift(e) {
      this.submit = true;
      let self = this;
      if (self.processing) {
        return;
      }
      self.processing = true;
      self.$axios
        .post("shift/employee/select-shift", {
          employee_id: this.currentUser.employee.id,
          shift_id: this.editevent.shift.id,
          role_id: this.shift_role_id,
          status: "Pending",
        })
        .then((response) => {
          let data = response.data;
          this.eventModal = false;
          self.triggerSwal(data.message, "success");
          // this.fetchSelectedShift();
          let table_data_param = {
            from : this.table_data.from,
            to : this.table_data.to
          }
          this.$emit('weeklySelectedShifts');
          this.$emit('selectedShifts' , {callApi : true} ,table_data_param);
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {
          self.processing = false;
        });
    },

    releaseShift() {
      this.submit = true;
      let self = this;
      if (self.processing) {
        return;
      }
      self.processing = true;
      if (self.select_release_reason != "other") {
        self.release_reason = self.select_release_reason;
      }
      let fd = new FormData();
      fd.append("release_docs", self.release_document);
      fd.append("status", self.release_status);
      fd.append("shift_id", self.shift_id);
      fd.append("employee_id", self.currentUser.employee.id);
      fd.append("action_detail", self.release_reason);

      self.$axios
        .post("shift/employee/shift-action/" + self.current_timeline_id, fd , {params : {validateRelease : true}})
        .then((response) => {
          let data = response.data;
          self.closeModal();
          self.triggerSwal(data.message, "success");
           let table_data_param = {
            from : this.table_data.from,
            to : this.table_data.to
          }
          this.$emit('weeklySelectedShifts');
          this.$emit('selectedShifts' , {callApi : true} , table_data_param);
        })
        .catch((error) => {
           self.handleErrorResponse(error.response, "error");
        })
        .finally(function () {
          self.processing = false;
          self.release_reason = null;
          self.select_release_reason = null;
          self.release_document = null;
        });
    },

    /**
     * Modal open for edit event
     */
    editEvent(info) {

      
      this.edit = info.event;
      this.editevent.editTitle = this.edit.title;
      this.editevent.status = this.edit.extendedProps.status;
      this.editevent.timeline_id = this.edit.extendedProps.timeline_id;

      this.editevent.shift = { ...this.edit.extendedProps.shift };
      this.shift_role_id = this.edit.extendedProps.shift.shift_roles[0].pivot.id;
      // this.shift_role_id = this.edit.
      if (
        (this.edit.extendedProps.status != "Rejected" &&
        this.edit.extendedProps.status != "Released" &&
        this.edit.extendedProps.status != "Apply For Leave" )
        &&
        new Date(this.edit.extendedProps.shift.from) >  new Date()
      ) {
        // this.getSelectedRoleForEditShift(this.editevent.shift);
        this.eventModal = true;
      }
    },


    closeModal() {
      this.eventModal = false;
      this.releaseShiftModel = false;
      this.shift_role_id = null;
    },

    confirm(shift_id, status, timeline_id) {
      this.shift_id = shift_id;
      this.release_status = status;
      this.current_timeline_id = timeline_id;
      Swal.fire({
        title: "Are you sure?",
        text: "You want to Release Shift!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, release it!",
      }).then((result) => {
        if (result.value) {
          if (status == "Apply For Leave") {
            this.releaseShiftModel = true;
          }
          if (status == "Pending") {
            this.releaseShift();
          }
        }
      });
    },

    confirmOverTimeShift() {
      let message =  this.currentUser.employee.minor == 1 ? "requested to schedule" : "scheduled" ;
      Swal.fire({
        title: "Are you sure?",
        text: "You have already " + message  + " a 40-hour shift for this week, if you need to work an overtime shift, your request will be forwarded to management for approval.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, Accept",
      }).then((result) => {
        if (result.value) {
            this.submitShift();
        }
      });
    },

    checkCounts()
    {
      this.$axios
      .get("counts-stats/index", {
        params: {employee_id : this.currentUser.employee.id , shift_id: this.editevent.shift.id }
      })
      .then((response) => {
        let totalHours = response.data.payload;
        if(totalHours > 40)
        {
          this.confirmOverTimeShift();
        }else{
          this.submitShift();
        }
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    setCalendarRef() {
      this.calendarRef = this.$refs.fullCalendar?.getApi();
      let view = this.calendarRef.view;
      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      this.$emit('calendarShift' , this.table_data);
      // this.fetchShift();
    },

    fetchNextData() {
      this.calendarRef.next();
      let view = this.calendarRef.view;
      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      // this.fetchShift();
      this.$emit('selectedShifts' ,{callApi : false} , this.table_data);
      setTimeout(() => {
        this.$emit('calendarShift' , this.table_data);
      }, 300);
    },
    fetchPrevData() {
      this.calendarRef.prev();
      let view = this.calendarRef.view;

      this.table_data.from = this.formatDateYMD(view.currentStart);
      this.table_data.to = this.formatDateYMD(view.currentEnd);
      // this.fetchShift();
      this.$emit('selectedShifts' ,{callApi : false} , this.table_data);
      setTimeout(() => {
        this.$emit('calendarShift' , this.table_data);
      }, 300);

    },
  },

  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  middleware: "authentication",
};
</script>

<template>
    <div class="row">
      <div class="">
        <h2 class="f-20 poppins">Schedules</h2>
        <!-- <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader> -->
        <div class="row" v-if="calendarOptions.events && loading==false">
          <FullCalendar
            class="vue-calendar-ui"
            v-if="renderCalendar"
            ref="fullCalendar"
            :options="calendarOptions"
            @hook:mounted="setCalendarRef"
          />
          
          <b-modal
            v-model="releaseShiftModel"
            title="Release Shift"
            title-class="text-black font-18"
            body-class="p-3"
            hide-footer
          >
            <form id="releaseShift">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper mt-3 pt-1">
                      <img
                        src="@/assets/images/profile-manager/user-icon.png"
                        width="21"
                        alt=""
                      />
                    </div>
                    <div class="form-field__wrapper w-100">
                      <label class=""
                        >Select Reason<span class="primaryColor"> * </span></label
                      >
                      <select
                        id="work_area"
                        v-model="select_release_reason"
                        class="form-select"
                      >
                        <option :value="'COVID quarantine required'">
                          COVID quarantine required
                        </option>
                        <option :value="'Cold/Flu like symptoms'">
                          Cold/Flu like symptoms
                        </option>
                        <option :value="'Death in family'">Death in family</option>
                        <option :value="'other'">other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-2" v-if="select_release_reason == 'other'">
                  <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper mt-3 pt-1">
                      <img
                        src="@/assets/images/profile-manager/icon-09.png"
                        width="21"
                        alt=""
                      />
                    </div>
                    <div class="form-field__wrapper w-100">
                      <b-form-group
                        id="input-group-2"
                        label="Type Reason"
                        label-for="input-2"
                      >
                        <textarea
                          class="form-control"
                          :maxlength="225"
                          rows="3"
                          v-model="release_reason"
                          placeholder="Write Reason"
                        ></textarea>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-4">
                  <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper mt-4 pt-1">
                      <img
                        src="@/assets/images/profile-manager/icon-10.png"
                        width="21"
                        alt=""
                      />
                    </div>
                    <div class="form-field__wrapper w-100">
                      <div class="col-lg-4">
                        <label for="resume">Upload Document</label>
                        <input
                          id="resume"
                          type="file"
                          @input="release_document = $event.target.files[0]"
                          class="form-control-file"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-end pt-5 mt-3">
                <button class="btn btn-outline-pink mx-3" type="button" @click="hideModal">Close</button>
                <SubmitButton
                  class="text-white"
                  text="Submit"
                  @clicked="releaseShift"
                  :processing="processing"
                ></SubmitButton>
              </div>
            </form>
          </b-modal>

          <b-modal
            v-model="eventModal"
            title="Shift"
            title-class="text-black poppins font-18"
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            body-class="p-3"
            hide-header-close
          >
            <div slot="modal-title poppins">Shift</div>
            <form @submit.prevent="submitShift">
              <div class="row">
                <div class="col-md-8 mb-3">
                  <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper mt-3 pt-1">
                      <img
                        src="@/assets/images/profile-manager/icon-10.png"
                        width="21"
                        alt=""
                      />
                    </div>
                    <div class="form-field__wrapper w-100">
                      <b-form-group
                        id="input-group-2"
                        label="Shift Name"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="editevent.editTitle"
                          readonly
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="d-flex align-items-center gap-2">
                    <div class="icon-wrapper mt-3 pt-1">
                      <img
                        src="@/assets/images/profile-manager/user-icon.png"
                        width="21"
                        alt=""
                      />
                    </div>
                    <div class="form-field__wrapper w-100">
                      <label class=""
                        >Select Role <span class="primaryColor"> * </span></label
                      >
                      <select :disabled="editevent.status == 'Pending' || editevent.status == 'Accepted'"
                        id="work_area"
                        v-model="shift_role_id"
                        class="form-select"
                      >
                        <option
                          :value="role.pivot.id"
                          v-for="(role, index) in editevent.shift.shift_roles"
                          :key="index"
                        >
                          {{ role.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-3">
                  <div class="d-flex gap-2">
                    <div class="col-md-12">
                      <div v-if="shift_role_id !=null" class="align-items-center d-flex gap-2">
                        <div class="icon-wrapper mt-3 pt-1">
                          <img
                            src="@/assets/images/profile-manager/icon-12.png"
                            width="21"
                            alt=""
                          />
                        </div>
                        <div class="form-field__wrapper w-100" v-if=" editevent.status != 'Pending' && editevent.status != 'Accepted'">
                          <label class="text-dark"
                            >Shift Time
                            <span class="primaryColor"> *</span></label
                          >
                          <select
                            id="time_slot"
                            class="form-select"
                          >
                            <option
                              :value="null"
                              selected
                            >
                              {{ changeTimeZone(editevent.from) }} -
                              {{ changeTimeZone(editevent.to) }}
                            </option>
                          </select>
                        </div>

                        <div class="form-field__wrapper w-100" v-else >
                          <label class="text-dark">Selected Shift Time <span class="primaryColor"> *</span></label>
                            <select  id="work_area" class="form-select" disabled>
                              <option :value="null">{{changeTimeZone(editevent.from)}}  - {{changeTimeZone(editevent.to)}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div
                  v-if="editevent.shift.shift_type == 'Premium'"
                  class="ms-4 mt-3 text-danger"
                >
                  Note: This is
                  <span class="text-uppercase"
                    >{{ editevent.shift.shift_type }}*</span
                  >
                  Shift. And {{ editevent.shift.shift_type }} Amount is
                  <span class="">{{ editevent.shift.bonus }}$</span>
                </div>
              </div>
              <div class="text-end p-3 pe-0">
                <button type="button" class="btn btn-outline-pink mx-3" @click="closeModal">Close</button>
                <SubmitButton
                  :processing="processing"
                  class="mx-2"
                  text="Accept"
                  @clicked="checkCounts()"
                  v-if="
                    editevent.status != 'Pending' && editevent.status != 'Accepted'
                  "
                />

              
                <SubmitButton
                  :processing="processing"
                  class="mx-2"
                  :style="{ background: 'rgb(244, 106, 106)' }"
                  text="Apply for Released"
                  @clicked="
                    confirm(
                      editevent.shift.id,
                      'Apply For Leave',
                      editevent.timeline_id
                    )
                  "
                  v-if="editevent.status == 'Accepted'"
                />

                <b-button
                  class="ms-1"
                  v-if="editevent.status == 'Pending'"
                  variant="danger"
                  @click="
                    confirm(editevent.shift.id, 'Pending', editevent.timeline_id)
                  "
                  >Apply for Released</b-button
                >
              </div>
            </form>
          </b-modal>
        </div>

        <div v-else-if="loading==false" class="row">
          <div class="card d-flex align-items-center justify-content-center p-4">
            <span><i class="uil-exclamation-circle fs-1"></i></span>
            <p class="fs-5 fw-bold roboto">Empty Shifts</p>
          </div>
        </div>
      </div> 
    </div>
</template>